var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cloudresourcelist-container"},[_c('div',{staticClass:"filter-container"},[_c('el-button',{staticClass:"filter-item",staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","icon":"el-icon-edit"},on:{"click":_vm.handleImport}},[_vm._v(" 导入 ")]),_c('el-button',{staticClass:"filter-item",staticStyle:{"margin-left":"20px"},attrs:{"type":"primary","icon":"el-icon-refresh-right"},on:{"click":_vm.handleRefresh}},[_vm._v(" 刷新 ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":"","default-sort":{prop: 'name', order: 'ascending'}}},[_c('el-table-column',{attrs:{"align":"center","label":"ID","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"align":"center","label":"Cloud Provider","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.cloudprovider))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"Instance name","prop":"name","sortable":"","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"Instance ID","prop":"instanceid","sortable":"","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.instanceid))])]}}])}),_c('el-table-column',{attrs:{"class-name":"status-col","align":"center","label":"Status","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(row.status)}},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"180px","align":"center","label":"Area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.instancearea))])]}}])}),_c('el-table-column',{attrs:{"width":"180px","align":"center","label":"Created Date","prop":"createddate","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.dateformat(row.createddate))}})]}}])}),_c('el-table-column',{attrs:{"width":"180px","align":"center","label":"Valid Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.dateformat(row.validdate))}})]}}])}),_c('el-table-column',{attrs:{"label":"Actions","align":"center","width":"160","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{attrs:{"round":"","size":"mini","type":"success"},on:{"click":function($event){return _vm.handlePreDeploy(row, $index)}}},[_vm._v(" 预装 ")]),_c('el-button',{attrs:{"round":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v(" 删除 ")])]}}])})],1),_c('el-dialog',{attrs:{"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{ref:"resourceForm",staticStyle:{"width":"90%","margin-left":"20px"},attrs:{"model":_vm.resourceform,"label-position":"left","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Type","prop":"category"}},[_c('el-select',{staticClass:"filter-item",attrs:{"placeholder":"Please select"},model:{value:(_vm.rsCategory),callback:function ($$v) {_vm.rsCategory=$$v},expression:"rsCategory"}},_vm._l((_vm.resourceTypes),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.display_name,"value":item.key}})}),1),_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleDiscovery()}}},[_vm._v(" 发现 ")])],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.resourcelist,"border":"","stripe":"","fit":"","highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"50"}}),_c('el-table-column',{attrs:{"align":"center","label":"ID","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"align":"center","label":"Name","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"UUID","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.instanceuuid))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"State","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.state))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"Address","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.address))])]}}])})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.importResource()}}},[_vm._v(" 确认 ")])],1)],1),(_vm.consoleVisible)?_c('el-dialog',{attrs:{"title":"执行结果","visible":_vm.consoleVisible},on:{"update:visible":function($event){_vm.consoleVisible=$event}}},[_c('div',{staticClass:"col s12 offset-m2 m8"},[_c('v-console',{staticStyle:{"height":"30vh"}})],1),_c('el-divider'),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDone}},[_vm._v(" 关闭 ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }