<template>
  <div class="cloudresourcelist-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="handleImport"
      >
        导入
      </el-button>
      <el-button
        class="filter-item"
        style="margin-left: 20px"
        type="primary"
        icon="el-icon-refresh-right"
        @click="handleRefresh"
      >
        刷新
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      :default-sort = "{prop: 'name', order: 'ascending'}"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        label="ID"
        type="index"
        width="50">
      </el-table-column>
      <!-- <el-table-column align="center" label="ID" width="80">
        <template slot-scope="{ row }">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column> -->

      <el-table-column align="center" label="Cloud Provider" min-width="100">
        <template slot-scope="{ row }">
          <span>{{ row.cloudprovider }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="Instance name"
        prop="name"
        sortable
        min-width="100"
      >
        <template slot-scope="{ row }">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="Instance ID"
        prop="instanceid"
        sortable
        min-width="100"
      >
        <template slot-scope="{ row }">
          <span>{{ row.instanceid }}</span>
        </template>
      </el-table-column>

      <el-table-column
        class-name="status-col"
        align="center"
        label="Status"
        width="110"
      >
        <template slot-scope="{ row }">
          <el-tag :type="row.status | statusFilter">
            {{ row.status }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column width="180px" align="center" label="Area">
        <template slot-scope="{ row }">
          <span>{{ row.instancearea }}</span>
        </template>
      </el-table-column>

      <el-table-column width="180px" align="center" label="Created Date" prop="createddate" sortable>
        <template slot-scope="{ row }">
          <!-- <span>{{ row.createddate }}</span> -->
          <span v-html="dateformat(row.createddate)"></span>
        </template>
      </el-table-column>

      <el-table-column width="180px" align="center" label="Valid Date">
        <template slot-scope="{ row }">
          <!-- <span>{{ row.validdate }}</span> -->
          <span v-html="dateformat(row.validdate)"></span>
        </template>
      </el-table-column>
      <el-table-column
        label="Actions"
        align="center"
        width="160"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row, $index }">
          <el-button
            round
            size="mini"
            type="success"
            @click="handlePreDeploy(row, $index)"
          >
            预装
          </el-button>
          <el-button
            round
            size="mini"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogFormVisible">
      <el-form
        ref="resourceForm"
        :model="resourceform"
        label-position="left"
        label-width="100px"
        style="width: 90%; margin-left: 20px"
      >
        <el-form-item label="Type" prop="category">
          <el-select
            v-model="rsCategory"
            class="filter-item"
            placeholder="Please select"
          >
            <el-option
              v-for="item in resourceTypes"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
          <el-button style="margin-left: 20px" type="primary" @click="handleDiscovery()"> 发现 </el-button>
        </el-form-item>
        <!-- <el-form-item label="File name" prop="filename">
          <el-input v-model="resourceform.filename" />
        </el-form-item> -->

      </el-form>
      <el-table
        :data="resourcelist"
        border
        stripe
        fit
        highlight-current-row
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column align="center"
          type="selection"
          width="50">
        </el-table-column>
        <el-table-column
          align="center"
          label="ID"
          type="index"
          width="50">
        </el-table-column>
        <el-table-column align="center" label="Name" width="180">
          <template slot-scope="{ row }">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="UUID" width="300">
          <template slot-scope="{ row }">
            <span>{{ row.instanceuuid }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="State" width="80">
          <template slot-scope="{ row }">
            <span>{{ row.state }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Address" width="180">
          <template slot-scope="{ row }">
            <span>{{ row.address }}</span>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false"> 取消 </el-button>
        <el-button
          type="primary"
          @click="importResource()"
        >
          确认
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="执行结果" :visible.sync="consoleVisible" v-if="consoleVisible">
      <div class="col s12 offset-m2 m8">
        <v-console style="height: 30vh"  />
      </div>

      <el-divider></el-divider>

      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="handleDone"
        >
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { fetchresourcelist } from "../../services/api/fetchresourcelist";
import { mapGetters } from "vuex";

import { discoveryresources } from "../../services/api/discoveryresources";
import { createresources } from "../../services/api/createresources";
import { deleteresource } from "../../services/api/deleteresource";
import { refreshresources } from "../../services/api/refreshresources";
import { updateresource } from "../../services/api/updateresource";

import sseEConsole from "@/components/sse-econsole";

const resourceTypes = [
  { key: "LOCALKVM", display_name: "Local KVM" },
  { key: "TENCENTCLOUD", display_name: "Tencent Cloud" },
  { key: "ALICLOUD", display_name: "Ali Cloud" },
  { key: "OTHER", display_name: "Other" },
];

export default {
  name: "cloudresourcelist",
  computed: {
    ...mapGetters(["name"]),
  },
  components: {
    "v-console": sseEConsole,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        running: "success",
        stopped: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
      },
      dialogFormVisible: false,
      rsCategory:"",
      resourceform: {
        category: "",
        cloudprovider: "",
        instanceid: "",
        name: "",
        status: "",
        instancearea: "",
        createddate: "",
        validdate: "",
        used: false,
        predeployed: false,
      },
      resourceTypes,
      resourcelist: [],
      multipleSelection: [],
      consoleVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    dateformat(rawdate){
      let t = rawdate;
      if (t){
        t = new Date(t);
        var year=t.getFullYear(), 
            month=t.getMonth()+1, 
            day=t.getDate(), 
            hour=t.getHours(), 
            min=t.getMinutes(), 
            sec=t.getSeconds();
        var newTime=year+'-'+
      　　  (month<10?'0'+month:month)+'-'+
     　　   (day<10?'0'+day:day)+' '+
      　　  (hour<10?'0'+hour:hour)+':'+
      　　  (min<10?'0'+min:min)+':'+
      　　  (sec<10?'0'+sec:sec);
        return newTime;
      }
      return;
    },
    async getList() {
      fetchresourcelist()
        .then((data) => {
          this.list = data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    handleImport() {
      this.resourcelist = [];
      this.multipleSelection = [];
      this.dialogFormVisible = true;
      this.rsCategory = "LOCALKVM";
    },
    importResource(){
      var rslist = [];
      this.multipleSelection.forEach(function (selection) {
        // console.log(selection.name);
        var rs = {
          category: "",
          cloudprovider: "",
          instanceid: "",
          name: "",
          status: "",
          instancearea: "",          
          createddate: "",
          validdate: "",
          ipaddress: "",
          used: false,
          predeployed: false,
        }; 
        // console.log(this.rsCategory)
        rs.instanceid = selection.instanceuuid;
        rs.name = selection.name;
        rs.category = this.rsCategory;
        // if (this.rsCategory === "LOCALKVM"){
        //   rs.cloudprovider = "Local KVM";
        //   rs.instancearea = "Local";
        // };
        rs.status = selection.state;
        rs.ipaddress = selection.address;
        rs.instancearea = selection.instancearea;
        // console.log(selection.state)
        // switch(selection.state){
        //   case 1:
        //     rs.status = "running";
        //     break;
        //   case 5:
        //     rs.status = "stopped";
        //     break;
        //   default:
        //     rs.status = "unknown";
        // };
        rslist.push(rs);
        // this.list.unshift(rs);
      }, this);

      createresources(rslist)
        .then((data) => {
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            rslist = JSON.parse(data.msg);

            rslist.forEach(function (rs) {
              this.list.unshift(rs);
            }, this);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });

      this.dialogFormVisible = false;
    },
    handleRefresh(){
      refreshresources()
        .then((data) => {
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: "刷新成功",
              type: "success",
            });

            var statuslist = JSON.parse(data.msg);
            statuslist.forEach(function (rs) {
              const index = this.list.findIndex(
                (v) => v.id === rs.id
              );
              this.list[index].status = rs.status;
            }, this);
            
          } else {
            this.$message({
              message: "无刷新",
              type: "info",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    handleDiscovery(){
      discoveryresources(this.rsCategory)
        .then((data) => {
          // console.log(data)
          this.resourcelist = data;
        })
        .catch((error) => {
          alert(error);
        });

    },
    handleSelectionChange(val){
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
    handlePreDeploy(row, index){
      this.consoleVisible = true;

      this.resourceform = Object.assign({}, row); // copy obj
      // console.log(this.resourceform.predeployed);
      // if (this.resourceform.predeployed) {
      //       this.$message({
      //         message: "已经预装所需软件",
      //         type: "info",
      //       });
      //     }
      

      updateresource(this.resourceform)
        .then((data) => {
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: data.msg,
              type: "success",
            });

            row.predeployed = true;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    handleDone(){
      this.consoleVisible = false;
    },
    handleDelete(row) {
      this.resourceform = Object.assign({}, row); // copy obj

      deleteresource(this.resourceform)
        .then((response) => {
          var res = response.status;

          // no data returned for status 204.
          if (res === 204) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            const index = this.list.findIndex(
                (v) => v.id === row.id
              );

            this.list.splice(index, 1);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cloudresourcelist {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}

.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}

</style>
