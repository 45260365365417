/* eslint-disable */
import { authRequest } from './auth';

const deleteresource = (resourcebody) => {
  const extraParameters = resourcebody;
  return authRequest.delete('/api/cloudresources/' + resourcebody.id + '/', extraParameters)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};

export { deleteresource }; // eslint-disable-line import/prefer-default-export
