import { authRequest } from './auth';

const updateresource = async (resourcebody) => {
  const extraParameters = { params: { uuid: resourcebody.instanceid } };
  try {
    const response = await authRequest.get('/api/predeployresouces/', extraParameters, { timeout: 300000 });
    return await Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { updateresource }; // eslint-disable-line import/prefer-default-export
