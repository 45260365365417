import { authRequest } from './auth';

const discoveryresources = (rsCategory) => {
  const extraParameters = { params: { category: rsCategory } };
  return authRequest.get('/api/discoveryresources/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { discoveryresources }; // eslint-disable-line import/prefer-default-export
