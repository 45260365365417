import { authRequest } from './auth';

const createresources = (resourcelist) => {
  const extraParameters = resourcelist;
  return authRequest.post('/api/cloudresources/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { createresources }; // eslint-disable-line import/prefer-default-export
