import { authRequest } from './auth';

const fetchresourcelist = async () => {
  const extraParameters = {};
  return authRequest.get('/api/cloudresources/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { fetchresourcelist }; // eslint-disable-line import/prefer-default-export
